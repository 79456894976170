.block2{
    &__content{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
    &__col{
        max-width: 50%;
        margin-top: 60px;
        & .btn{
            margin-top: 36px;
        }
        &:not(:last-child){
            margin-right: 60px;
        }
        &-title{
            color: var(--gray-base);
            font-size: 24px;
            font-weight: 600;
            line-height: 26px; 
            margin-bottom: 36px;
        }
        &-text{
            color: var(--gray-lighter-3);
            font-size: 20px;
            line-height: 26px; 
            margin-top: 24px;
            &.strong{
                font-weight: 600;
            }
        }

        &-list{
            color: var(--gray-lighter-3);
            font-size: 20px;
            line-height: 26px; 
            margin-top: 24px;
            padding-left: 15px;
            list-style: disc;
        }
    }
}

@media screen and (max-width: 768px){
    .block2{
        &__content{
            flex-direction: column;
        }
        &__col{
            max-width: 100%;
        }
    }
}

@media screen and (max-width: 576px) {
    .block2{
        &__col{
            margin-top: 44px;
            & .btn{
                margin-top: 18px;
                width: 100%;
            }
            &:not(:last-child){
                margin-right: 0;
            }
            &-title{
                margin-bottom: 18px;
            }
            &-text{
                margin-top: 12px;
            }
        }
    }
}
