.logo{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: opacity 250ms linear;
    &:hover, &:focus{
        opacity: 0.6;
    }
    &>img{
        display: inline-block;
    }
}

// @media screen and (min-width: 1101px) {
 
// }