.container{
    display: block;
    margin: 0 auto;
    padding: 0 16px;
    width: 100%;
}
@media screen and (min-width: 1322px) {
    .container{
        max-width: 1322px;
    }
}

@media screen and (min-width: 1280px) and (max-width: 1321px) {
    .container{
        max-width: 1280px;
    }
}
@media screen and (min-width: 992px) and (max-width: 1279px) {
    .container{
        max-width: 960px;
    }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
    .container{
        max-width: 720px;
    }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
    .container{
        max-width: 540px;
    }
}
