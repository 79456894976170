.loader{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    border-radius: 6px;
    top: 0;
    right: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: rgba(256, 256, 256, 0.5); 
    transition: all 100ms linear;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    &>i{
        color: var(--brand-accent);
        font-size: 48px;
        transition: all 100ms linear;
        animation: mymove 5s infinite;
        @keyframes mymove {
			from { transform: translate(-50%, -50%) rotate(0deg); }
			to {  transform: translate(-50%, -50%) rotate(360deg);  }
		}
    } 
    &.show{
        pointer-events: all;
        opacity: 1;
        visibility: visible;
    } 
}