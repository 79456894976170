.page404{
    &__wrap{
        padding: 120px 0 160px 0;
        background-image: url('../img/page404/page404.png');
        background-repeat: no-repeat;
        background-position: bottom right;
    }
    &__subtitle{
        color: var(--gray-lighter-3);
        font-size: 18px;
        margin-top: 24px;
    }
    & .btn{
        margin-top: 36px;
    }
}
@media screen and (min-width: 992px) and (max-width: 1279px) {
    .page404{
        &__wrap{
            background-position-x: 215%;
        }
    }
}

@media screen and (max-width: 991px) {
    .page404{
        &__wrap{
            background: none;
        }
    }
}

@media screen and (max-width: 576px) {
    .page404{
        &__wrap{
            padding: 60px 0 80px 0;
        }
        &__subtitle{
            margin-top: 12px;
        }
        & .btn{
            margin-top: 18px;
            width: 100%;
        }
    }
}
