.btn{
    display: inline-block;
    padding: 9px 18px;
    border-radius: 8px;
    min-width: 115px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    transition: all 100ms linear;
    color: var(--gray-base);
    cursor: pointer;
    border: 1px solid var(--divider-theme);

    &:hover{
        opacity: 0.8;
    }
    &-lg{
        font-size: 18px;
        padding: 15px 24px;
    }
    &-primary{
        border: 1px solid var(--brand-accent);
        background-color: var(--brand-accent);
        color: var(--primary-btn-color);
    }
    &-secondary{
        background: #fff;
    }
}
