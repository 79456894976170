.block9{
    &__wrap{
       display: grid;
    }
    &.three{
        & .block9__wrap{
            grid-template-columns: 1fr 1fr 1fr;
            grid-column-gap: 30px;
            grid-row-gap: 30px;
        }
        & .block9__col{
            padding: 36px;
        }
    }
    &.four{
        & .block9__wrap{
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-column-gap: 20px;
            grid-row-gap: 20px;
        }
        & .block9__col{
            padding: 36px 28px;
        }
    }
    &__col{
        min-width: 200px;
        border-radius: 6px;
        background: var(--content-theme-background);

        &>img{
            display: block;
            width: 100px;
            height: 100px;
            object-fit: cover;
        }

        &-title{
            color: var(--gray-lighter);
            font-size: 24px;
            font-weight: 600;
            margin-top: 24px;
            &>span{
                white-space: nowrap;
            }
        }
        &-text{
            color: var(--gray-lighter);
            font-size: 18px;
            margin-top: 16px;
            font-weight: 400;
        }
    }
}

@media screen and (max-width: 1279px) {
    .block9{
        &.four{
            & .block9__wrap{
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 30px;
                grid-row-gap: 30px;
            }
        }
    }
}
@media screen and (max-width: 991px) {
    .block9{
        &.three{
            & .block9__wrap{
                grid-template-columns: 1fr 1fr;
            }
        }
    }
}


@media screen and (max-width: 767px) {
    .block9{
        &.three, &.four{
            & .block9__wrap{
                grid-template-columns: 1fr;
            }
        }
        &__wrap{
           grid-template-columns: 1fr;
        }
        &__col{
            min-width: 200px;
            width: 100%;
        }
    }
}

@media screen and (max-width: 576px){
    .block9{
        &__wrap{
           grid-column-gap: 16px;
           grid-row-gap: 16px;
        }
    }
}