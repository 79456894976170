.block5{
    &__table{
        display: grid;
        grid-template-columns: auto auto auto max-content;
        position: relative;
        margin-top: 60px;
        &-wrap{
            overflow: auto;
        }
    }

    &__bnr{
        grid-area: auto;
        background: var(--content-theme-background);
    }

    &__priceblock{
        min-width: 300px;
        padding: 48px 16px 24px 16px;
        grid-area: auto;

        border-left: 1px solid var(--divider-theme);
        background-color: var(--content-theme-background);
        text-align: center;
        &-title{
            color: var(--gray-lighter-2);
            font-size: 24px;
        }
        &-price{  
            color: var(--gray-lighter-2);
            font-size: 36px;
            font-weight: 600;
            margin-top: 24px;
            line-height: 1;
            &>span{
                color: var(--gray-lighter-3);
                font-size: 24px;
                font-weight: 400;
            }
        }
        &-btn{
            display: block;
            width: 100%;
            margin-top: 16px;
        }
        &-link{
            display: block;
            color: var(--gray-lighter-2);
            font-size: 16px;
            font-weight: 600;
            text-decoration-line: underline;
            transition: all 100ms linear;
            margin-top: 16px;
            &:hover{
                opacity: 0.6;
            }
        }
        &-wrap{
            display: contents;
        }
    }
 
    &__features{
        grid-area: auto;
        background: var(--content-theme-background);
        border-left: 1px solid var(--divider-theme);

        &-title{ 
            grid-area: auto;
            display: block;
            position: relative;
            text-align: left;
            background: var(--content-theme-background);
            padding: 24px 24px 24px 36px;

            color: var(--gray-lighter);
            font-size: 20px;
            font-weight: 600;
            min-width: 300px;
        }

        &-name{
            display: none;
            color: var(--gray-lighter);
            font-size: 20px;
            font-weight: 600;
            margin-right: 10px;
        }
        
        &>span{
            padding: 24px 30px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;

            color: var(--gray-lighter-3);
            font-size: 20px;
        }   
        
        &-wrap{
            display: contents;
            &.last-row{
                & .block5__features-title{
                    padding: 24px 36px 24px 36px;
                }
                & .block5__features>span{
                    padding: 24px 30px 36px 30px;
                }
            }
        }
    }

}

@media screen and (max-width: 767px){
    .block5{
        &__table{
            margin-top: 30px;
            grid-template-columns: auto auto max-content;
        }

        &__bnr{
            display: none;
        }
    
        &__priceblock{
            padding: 24px 16px 12px 16px;
            &.priceblock-1{
                border: none;
            }
        }
     
        &__features{
            &.feature-1{
                border: none;
            }
            &-title{ 
                display: none;
            } 

            &-name{
                display: inline-block;
            }

            &>span{
                padding: 16px;
            } 
            
            &-wrap{
                &.last-row{
                    & .block5__features>span{
                        padding: 16px 16px 24px 16px;
                    }
                }
            }
        }
    
    }
}


