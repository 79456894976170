.block7{
    &__content{
        margin-top: 36px;
        color: var(--gray-lighter-3);
        font-size: 20px;
        & p, & ul, & ol{
            &:not(:last-child){
                margin-bottom: 24px;
            }
        }
    }
    &__col{
        width: 50%;
        &:first-child{
            padding-right: 60px;
        }
        &-wrap{
            margin-top: 60px;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        }
        &-title{
            color: var(--gray-base);
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 36px;
        }
        &-text{
            color: var(--gray-lighter-3);
            font-size: 20px;
            &:not(:last-child){
                margin-bottom: 16px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .block7{
        &__col{
            width: 100%;
            &:first-child{
                padding-right: 0;
                margin-bottom: 30px;
            }
            &-wrap{
                display: block;
            }
            &-title{
                margin-bottom: 16px;
                line-height: 1;
            }
        }
    }
}

@media screen and (max-width: 576px){
    .block7{
        &__content{
            margin-top: 18px;
            & p, & ul, & ol{
                &:not(:last-child){
                    margin-bottom: 12px;
                }
            }
        }
        &__col{
            &-wrap{
                margin-top: 30px;
            }
            &-text{
                &:not(:last-child){
                    margin-bottom: 12px;
                }
            }
        }
    }
}