.block11{
    &__content{
        color: var(--gray-lighter-2);
        font-size: 18px;
        line-height: 1.3;
        margin: 0 auto;
        max-width: 1110px;

        & p{
            margin-bottom: 10px;    
        } 
        & ol{
            padding-left: 20px;
            &>li{
                &:not(:last-child){
                    margin-bottom: 10px;   
                } 
            }
        } 
        & h2, & h3{
            margin: 24px 0;
            font-size: 20px;
            font-weight: 600;
        } 

    }
}