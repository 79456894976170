.block6 {
    &__wrap{
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-start;
        justify-content: space-between;
    }
    &__left{
        padding-right: 60px;
        width: 35%;
    }
    &__right{
        width: 65%;
    }

	&__item {
        background: var(--content-theme-background);
        padding: 24px 32px;
		cursor: pointer;
        border-radius: 6px;
		&-wrap{
			&:not(:last-child){
				margin-bottom: 16px;
			}
			&.active {
				& .block6__title {
                    &>i, &>svg {
						transform: rotateZ(-180deg) translateY(50%);
					}
				}
			}
		}
	}

	&__title {
        color: var(--gray-lighter);
        font-size: 24px;
        font-weight: 600;
		position: relative;
		padding-right: 25px;
		transition: all 100ms linear;
        &>i, &>svg {
            position: absolute;
			top: 50%;
			right: 0;
            color: var(--gray-icons);
            font-size: 22px;
            transform: translateY(-50%);
			transition: all 100ms linear;
			font-size: 20px;
    	}
	}

	&__text {
		display: none;
		color: var(--gray-lighter-2);
		padding-top: 5px;

		& p {
			margin-top: 12px;
		}

		& ul {
            padding-left: 20px;
            list-style: disc;
			& > li {
				margin-top: 5px;
				position: relative;
				text-align: left;
				& a {
					margin-top: 0;

                    transition: all 100ms linear;
                    &:hover{
                        opacity: 0.6;
                    }
				}			
			}
		}

	}
}

@media screen and (max-width: 991px){
    .block6 {
        &__wrap{
            display: block;
        }
        &__left{
            padding-right: 0;
            width: 100%;
        }
        &__right{
            margin-top: 32px;
            width: 100%;
        }
    }
}

@media screen and (max-width: 576px){
    .block6 {
        &__right{
            margin-top: 18px;
        }
        &__item {
            padding: 12px 16px;
        }
    }
}