.block15{
    &__wrap{
        margin-top: 60px;
    }
    &__table{
        width: 100%;
        border-collapse: collapse;
        &>tbody{
            &>tr{
                &>th{
                    background-color: var(--content-theme-background);
                    padding: 48px 24px 24px 24px;
                    width: 33%;
                    color: var(--gray-lighter-2);
                    font-size: 24px;
                    font-weight: 400;
                    &:nth-child(2){
                        font-weight: 600;
                    }
                    &:not(:last-child){
                        border-right: 1px solid var(--divider-theme);
                    }
                }           
                &>td{
                    width: 33%;
                    background-color: var(--content-theme-background);
                    padding: 32px 36px;
                    text-align: center;
                    &>.fa-check{
                        font-size: 16px;
                        color: var(--brand-success);
                    }
                    &>.fa-xmark{
                        font-size: 16px;
                        color: var(--brand-danger);
                    }
                    &>.hint{
                        display: none;
                        position: absolute;
                        top: 50%;
                        right: -300px;
                        padding: 10px;
                        background-color: rgba(0, 0, 0, 0.6);
                        border-radius: 5px;
                        transform: translateY(-50%);
                        max-width: 300px;
                        font-weight: 400;
                        &>span{
                            color: #f5f5f5;
                            font-size: 14px;
                            line-height: 1.4;
                            display: block;
                            margin-top: 5px;
                            &:first-child{
                                margin-top: 0;
                            }
                        }
                        &::after{
                            content: '';
                            position: absolute;
                            z-index: -1;
                            top: 50%;
                            left: -6px;
                            width: 0px;
                            height: 0px;
                            border-style: solid;
                            border-width: 0 6px 9px 6px;
                            border-color: transparent transparent rgba(0, 0, 0, 0.6) transparent;
                            transform: rotate(270deg) translateY(-50%);
                        }
                        &.show{
                            display: block;
                        }
                    }
                    &:first-child{
                        color: var(--gray-lighter);
                        font-size: 20px;
                        font-weight: 600;
                        position: relative;
                        padding: 32px 54px 32px 36px;
                        text-align: left;
                        line-height: 1;
                    }
                    &:not(:last-child){
                        border-right: 1px solid var(--divider-theme);
                    }
                }
            }
        }
    }
    &__btn{
        position: absolute;
        top: 50%;
        right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        background-color: transparent;
        border: none;
        outline: none;
        color: var(--divider-theme);
        font-size: 14px;
        cursor: pointer;
        transform: translateY(-50%);
        transition: all 100ms linear;
        &:hover{
            opacity: 0.6;
        }
        &>i{
            pointer-events: none;
        }
    }
}

@media screen and (max-width: 991px) {
    .block15{
        &__wrap{
            overflow: auto;
        }
        &__table{
            &>tbody{
                &>tr{
                    &>th{
                        min-width: 300px;
                    }
                    &>td{
                        min-width: 300px;
                    }
                }
            }
        }
    }
}