.block3{
    overflow: hidden;
    &__table{
        border-spacing: 0 16px;
        width: 100%;
        &-wrap{
            width: 100%;
            overflow: auto;
        }
        &>tbody{
            &>tr{
                &>th{
                    color: var( --gray-lighter-3);
                    font-size: 16px;
                    font-weight: 400;
                }
                &>td{
                    text-align: center;
                    background-color: var(--content-theme-background);
                    padding: 16px 18px;
                    font-size: 18px;
                    &:first-child{
                        text-align: start;
                        padding: 16px 80px 16px 36px;
                        border-radius: 6px 0 0 6px;
                        font-size: 20px;
                        font-weight: 600;
                        line-height: 22px;
                    }
                    &:last-child{
                        text-align: end;
                        padding: 16px 36px 16px 18px;
                        border-radius: 0 6px 6px 0;
                    }
                    &.price{
                        font-weight: 600;
                        padding: 16px 18px 16px 80px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1279px) {
    .block3{
        &__table{
            &>tbody{
                &>tr{
                    &>td{
                        font-size: 16px;
                        padding: 16px 12px;
                        &:first-child{
                            min-width: 140px;
                            font-size: 18px;
                            padding: 16px 12px 16px 24px;
                        }
                        &:last-child{
                            padding: 16px 24px 16px 12px;
                        }
                        &.price{
                            padding: 16px 12px;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 991px) {
    .block3{
        &__table{
            &>tbody{
                &>tr{
                    &>th{
                        min-width: 180px;
                    }
                    &>td{
                        min-width: 180px;
                    }
                }
            }
        }
    }
}