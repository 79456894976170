.block1{
    &__wrap{
        padding: 120px 0 80px 0;
        background-image: url('../img/block1/block1.png');
        background-repeat: no-repeat;
        background-position: bottom right;
    }
    &__subtitle{
        color: var(--gray-lighter-3);
        font-size: 18px;
        margin-top: 24px;
    }
    &__actions{
        margin-top: 36px;
        &>.btn-secondary{
            margin-left: 10px;
        }
    }
    &__advantages{
        margin-top: 36px;
        list-style: disc;
        padding-left: 20px;
        line-height: 1.3;
        &>li{
            &:not(:last-child){
                margin-bottom: 12px;
            }
        }
    }
}
@media screen and (min-width: 992px) and (max-width: 1279px) {
    .block1{
        &__wrap{
            background-position-x: 215%;
        }
    }
}

@media screen and (max-width: 991px) {
    .block1{
        &__wrap{
            background: none;
        }
    }
}

@media screen and (max-width: 576px) {
    .block1{
        &__wrap{
            padding: 60px 0 40px 0;
        }
        &__subtitle{
            margin-top: 12px;
        }
        &__actions{
            margin-top: 18px;
        }
        &__advantages{
            margin-top: 18px;
            &>li{
                &:not(:last-child){
                    margin-bottom: 6px;
                }
            }
        }
        &__actions{
            &>.btn{
                width: 100%;
            }
            &>.btn-secondary{
                margin: 16px 0 0 0;
            }
        }
    }
}
