.tooltip{
    position: fixed;
    display: inline-block;
    top: 120px;
    right: 30px;
    z-index: 1;
    padding: 10px 20px;
    font-size: 24px;
    font-weight: 400;
    border-radius: 8px;
    transition: all 100ms linear;
    opacity: 0;
    visibility: hidden;
    transform: translateX(60px);
    &-success{
        border: 1px solid var(--brand-success);
        background: var(--brand-success-lighter-3);
        color: var(--brand-success);
    }
    &-error{
        border: 1px solid var(--brand-danger);
        background: var(--brand-danger-lighter-2);
        color: var(--brand-danger);
        & a{
            color: var(--brand-danger);
            text-decoration: underline;
            transition: all 100ms linear;
            &:hover{
                opacity: 0.6;
            }
        }
    }
    &.show{
        opacity: 1;
        visibility: visible;
        transform: translateX(0);
    }
}