.section{
    padding: 80px 0;
    &-title{
        color: var(--gray-lighter-2);
        font-size: 40px;
        font-weight: 800;
        line-height: 46px;
    }
    &-subtitle{
        font-size: 20px;
        font-weight: 400;
        line-height: 26px; 
        margin-top: 24px;
        color: var(--gray-lighter-3);
    }
    &.pt-16{
        padding-top: 160px;
    }
    &.pb-16{
        padding-bottom: 160px;
    }
}
.page-title{
    color: var(--gray-lighter-2);
    font-size: 60px;
    font-weight: 800;
    line-height: 65px;
}

.pt-16{
    padding-top: 160px;
}
.pb-16{
    padding-bottom: 160px;
}

.pt-8{
    padding-top: 80px;
}
.pb-8{
    padding-bottom: 80px;
}

.pt-4{
    padding-top: 40px;
}
.pb-4{
    padding-bottom: 40px;
}

@media screen and (max-width: 576px){
    .section{
        padding: 20px 0;
        &-title{
            font-size: 36px;
            line-height: 1.2;
        }
        &-subtitle{
            margin-top: 12px;
        }
        &.pt-16{
            padding-top: 80px;
        }
        &.pb-16{
            padding-bottom: 80px;
        }
    }
    .pt-16{
        padding-top: 80px;
    }
    .pb-16{
        padding-bottom: 80px;
    }
    .pt-8{
        padding-top: 40px;
    }
    .pb-8{
        padding-bottom: 40px;
    }
    .pt-4{
        padding-top: 20px;
    }
    .pb-4{
        padding-bottom: 20px;
    }
    .page-title{        
        font-size: 48px;
        line-height: 1.4;
    }
}