.block8{
    &__wrap{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
    }
    &__content{
        max-width: 720px;
        color: var(--gray-lighter-2);
        font-size: 20px;
        margin-right: 60px;
        flex-grow: 1;
        &>p{
            &:not(:last-child){
                margin-bottom: 30px;
            }
        }
    }
    &__name{
        display: block;
        color: var(--gray-base);
        font-size: 24px;
        font-weight: 600;
        margin-top: 36px;
    }
    &__position{
        display: block;
        color: var(--gray-lighter-3);
        font-size: 20px;
        line-height: 1;
    }
    &__img{
        display: block;
        width: 410px;
        height: 410px;
        object-fit: cover;
        border-radius: 6px;
    }
}
@media screen and (max-width: 991px){
    .block8{
        &__wrap{
            flex-direction: column-reverse;
            align-items: center;
            justify-content: space-between;
        }
        &__content{
            max-width: 100%;
            margin: 60px 0 0 0;
        }
    }
}

@media screen and (max-width: 576px) {
    .block8{
        &__content{
            margin: 30px 0 0 0;
            &>p{
                &:not(:last-child){
                    margin-bottom: 15px;
                }
            }
        }
        &__name {
            margin-top: 18px;
        }
        &__img{
            width: 100%;
        }
    }
}