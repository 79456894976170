.footer{
    color: var(--gray-lighter-3);
    overflow: hidden;
    background: var(--content-theme-background);
    border-top: 1px solid var(--divider-theme);
    padding-top: 30px;
    &__nav{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        &-wrap{
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 30px;
            margin-bottom: 60px;
            border-bottom: 1px solid var(--divider-theme);
        }
        &-item{
            padding: 6px 0;
            &:not(:last-child){
                margin-right: 30px;
            }
            &>a{
                font-weight: 600;
                color: var(--gray-lighter-2);
                cursor: pointer;
                transition: all 100ms linear;
                &:hover{
                    opacity: 0.8;
                }
            }
        }
    }
    &__content{
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        &-payment{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            max-width: 180px;
            &>ul{
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                justify-content: space-between;
                width: 100%;
                &>li{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 80px;
                    height: 40px;
                    margin: 5px;
                    border-radius: 6px;
                    padding: 8px 0;
                    background: var(--content-theme-background);
                    border: 1px solid var(--divider-theme);
                    &>img{
                        display: inline-block;
                    }
                }
            }
            &>.openstack{
                display: block;
                width: 100%;
                max-width: 200px;
                margin-top: 30px;
            }
        }
    }
    &__company{
        line-height: 1;
        margin-top: -5px;
    }
    &__address{
        margin-top: 30px;
    }
    &__bottom{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
        margin-top: 60px;
        padding: 30px 0 50px 0;
        border-top: 1px solid var(--divider-theme);
    }
    &__social{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
        &-item{
            &:not(:last-child){
                margin-right: 24px;
            }
            &>a{
                display: flex;
                align-items: center;
                justify-content: center;
                color: var(--gray-lighter-3);
                transition: all 100ms linear;
                &:hover{
                    opacity: 0.8;
                }
                &>i, &>svg{
                    display: inline-block;
                    color: var(--gray-lighter-3);
                    font-size: 24px;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .footer{
        padding-top: 30px;
        padding-bottom: 90px;
        &__nav{
            &-wrap{
                display: none;
            }
        }
        &__bottom{
            margin-top: 30px;
        }
    }
}


@media screen and (max-width: 576px){
    .footer{
        padding-top: 30px;
        // &__nav{
        //     margin-bottom: 16px;
        //     flex-direction: column;
        //     &-item{
        //         &:not(:last-child){
        //             margin: 0 0 10px 0;
        //         }
        //     }
        // }
        &__content{
            flex-direction: column;
            text-align: center;
            &-info{
                margin-bottom: 36px;
            }
        }
        &__bottom{
            flex-direction: column-reverse;
            text-align: center;
        }
        &__social{
            margin-bottom: 26px;
        }
    }
}