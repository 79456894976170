.header{
    background-color: var(--content-theme-background);
    font-weight: 600;
    border-bottom: 1px solid var(--divider-theme);
    &__wrap{
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        padding: 18px 0;
    }
    
    /*
    &__nav{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
        margin-left: 40px;

        &-wrap{
            margin-right: 40px;
            display: flex;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
        }
        &-item{
            padding: 6px 0;
            &:not(:last-child){
                margin-right: 32px;
            }
            &>a{
                color: var(--gray-lighter-2);
                cursor: pointer;
                transition: all 100ms linear;
                &:hover{
                    opacity: 0.8;
                }
            }
        }
    }
    */
    &__link{
        display: none;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px){
    .header{
        &__nav{
            &-wrap{
                margin: 0;
            }
        }
        &__btn, &__link{
            display: none !important;
        } 
    }
}
@media screen and (min-width: 768px) {
    .header{
        &__nav{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: nowrap;
            margin-left: 40px;
            
            &-wrap{
                margin-right: 40px;
                display: flex;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
            }
            &-item{
                padding: 6px 0;
                &-button{
                    display: none;
                }
                &:not(:last-child){
                    margin-right: 32px;
                }
                &>a{
                    &>i, &>svg{
                        display: none;
                    }
                    color: var(--gray-lighter-2);
                    cursor: pointer;
                    transition: all 100ms linear;
                    &:hover{
                        opacity: 0.8;
                    }
                }
            }
        }
        &__btn, &__link{
            &>i, &>svg{
                display: none;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .header{
        &__nav{
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-around;
            width: 100%;
            position: fixed;
            bottom: 0;
            left: 0;
            z-index: 2;
            border-top: 1px solid var(--divider-theme);
            
            &-item{
                background-color: var(--content-theme-background);
                width: 20%;
                position: relative;
                z-index: 2;
                &-button{
                    display: inline-flex;
                    width: 20%;
                    min-width: 65px;

                    &>a, &>button{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        top: -20px;
                        left: 50%;
                        border: none;
                        border-radius: 50%;
                        width: 65px;
                        height: 65px;
                        background: var(--brand-accent);
                        transform: translateX(-50%);
                        cursor: pointer;
                        color: var(--primary-btn-color);
                        font-size: 24px;
                        transition: background 100ms linear;
                        &:hover{
                            background: var(--brand-accent-lighter);
                        }

                        &::before {
                            content: "";
                            position: absolute;
                            bottom: -6%;
                            left: 50%;
                            z-index: -1;
                            width: 112%;
                            height: 112%;
                            border-radius: 50%;
                            transform: translateX(-50%);
                            box-shadow: 0 325px 0px 301px var(--content-theme-background);
                        }
                    }
                    &>a{
                        display: none;
                    }
                }
                &>a{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: 12px 16px;
                    color: var(--gray-icons);
                    text-align: center;
                    &>i, &>svg{
                        font-size: 22px;
                    }
                    &>span{
                        display: inline-block;
                        margin: 6px 0 0 0;
                        font-size: 10px;
                        line-height: 1;
                    }
                    transition: color 100ms linear;
                    &:hover{
                        color: var(--brand-accent);
                    }
                }
            }

            &-wrap{
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }
        }
        &__btn, &__link{
            background-color: transparent;
            border-radius: 0;
            border: none;
            color: var(--gray-base);
            font-size: 22px;
            font-weight: 400;
            padding: 10px 0 10px 25px;
            text-align: right;
            min-width: 40px;
            &>span{
                display: none;
            }
        }
    }
}
/*

@media screen and (min-width: 768px) and (max-width: 991px) {
    .header{
        &__nav{
            margin-left: 20px;
            &-wrap{
                width: 100%;
                margin: 0;
                justify-content: space-between;
            }
            &-item{
                &:not(:last-child){
                    margin-right: 16px;
                }
            }
        }
    }
}

@media screen and (max-width: 768px){
    .header{
        &__nav{
            margin: 18px 0 0 0;
            width: 100%;
            justify-content: space-between;
            &-wrap{
                flex-direction: column;
                align-items: flex-start;
                width: 100%;
                margin: 0;
            }
        }
    }
}


@media screen and (max-width: 576px) {
    .header{
        &__nav{
            overflow: auto;
            &-item{
                &>a{
                    white-space: nowrap;
                }
            }
        }
    }
}
*/