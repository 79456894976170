.block12{
    &__wrap{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
    &__form{
        flex-grow: 1;
        position: relative;
        &-title{
            margin-bottom: 24px;
        }
        &-text{
            color: var(--gray-lighter-3);
            font-size: 20px;
            font-weight: 400;
            line-height: 26px;
            margin-top: 12px;
        }
    }
    &__fields{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 32px;
        margin-bottom: 16px;
        & .block12__group{
            width: 50%;
            &:not(:last-child){
                margin-right: 16px;
            }
        }
    }
    &__btn{
        margin-top: 32px;
        min-width: 200px;
    }
    label{
        color: var(--gray-lighter-3);
        font-size: 20px;
        font-weight: 400;
        line-height: 26px;
        margin-bottom: 12px;
        &.error{
            margin: 0;
            font-size: 14px;
            font-weight: 400;
            line-height: 1;
            color: var(--brand-danger);
        }
    }
    input{
        display: block;
        width: 100%;
        border-radius: 6px;
        border: 1px solid var(--divider-theme);
        background: var(--content-theme-background);
        font-size: 20px;
        font-weight: 400;
        line-height: 26px;
        color: var(--gray-base);
        height: 46px;
        padding: 10px;
        outline: none;
        transition: all 100ms linear;
        &:hover, &:focus{
            border-color: var(--brand-accent);
        }
    }
    textarea{
        display: block;
        width: 100%;
        border-radius: 6px;
        border: 1px solid var(--divider-theme);
        background: var(--content-theme-background);
        resize: vertical;
        font-family: var(--rb);
        font-size: 20px;
        font-weight: 400;
        line-height: 26px;
        margin-bottom: 12px;
        color: var(--gray-base);
        padding: 10px;
        outline: none;
        transition: all 100ms linear;
        &:hover, &:focus{
            border-color: var(--brand-accent);
        }
    }

    &__sidebar{
        min-width: 300px;
        margin-left: 30px;
        &-title{
            color: var(--gray-lighter-2);
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 32px;
        }
        &-text{
            color: var(--gray-lighter-3);
            font-size: 20px;
            &:not(:last-child){
                margin-bottom: 20px;
            }
        }
        &-contacts{
            color: var(--gray-lighter-3);
            font-size: 20px;
            margin: 32px 0;
            &>a{
                color: var(--gray-lighter-3);
                transition: all 100ms linear;
                &:hover{
                    opacity: 0.8;
                }
            }
        }
    }
    &__social{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
        padding-top: 32px;
        position: relative;
        &::before{
            content: '';
            background-color: var(--divider-theme);
            display: block;
            width: 100%;
            height: 1px;
            position: absolute;
            top: 0;
            left: 0;
        }
        &-item{
            &:not(:last-child){
                margin-right: 24px;
            }
            &>a{
                display: flex;
                align-items: center;
                justify-content: center;
                color: var(--gray-lighter-3);
                transition: all 100ms linear;
                &:hover{
                    opacity: 0.8;
                }
                &>i, &>svg {
                    display: inline-block;
                    color: var(--gray-lighter-3);
                    font-size: 24px;
                }
            }
        }
    }
}
.captcha_wrap{
    display: none;
}
.grecaptcha-badge{
    display: none;
}

@media screen and (max-width: 991px) {
    .block12{
        &__wrap{
            display: block;
        }
        &__sidebar{
            margin: 80px 0 0 0;
        }
    }
}
@media screen and (max-width: 767px) {
    .block12{
        &__fields{
            flex-direction: column;
            & .block12__group{
                width: 100%;
                &:not(:last-child){
                    margin: 0 0 16px 0;
                }
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .block12{
        &__form{
            &-title{
                margin-bottom: 12px;
            }
            &-text{
                margin-top: 12px;
            }
        }
        &__fields{
            margin-top: 16px;
        }
        &__btn{
            width: 100%;
            margin-top: 16px;
        }
    }
}