@import '@awesome.me/kit-cbcc708f20/icons/scss/fontawesome.scss';
@import '@awesome.me/kit-cbcc708f20/icons/css/solid.css';
@import '@awesome.me/kit-cbcc708f20/icons/css/brands.css';
// light theme
:root {
    //Brands
    --gray-base: #313131;
    --gray-lighter: #333333;
    --gray-lighter-2: #393D45;
    --gray-lighter-3: #6F6F6F;
    --gray-lighter-4: #EEEEEE;
    --gray-faded: #FFFFFF; 
    --gray-darker: #000000;
    --brand-primary: #FBAA00;
    --brand-primary-lighter: #feb65b;
    --brand-primary-lighter-2: #FFB354;
    --brand-primary-lighter-3: #FFD298;
    --brand-primary-lighter-4: #FFE1BB;
    --brand-primary-darker: #ED8000;
    --brand-primary-gradient-start: #5C9620;
    --brand-primary-gradient-end: #71AC34;
    --brand-secondary: #EF9440;
    --brand-secondary-lighter: #F5AA66;
    --brand-secondary-darker: #EE7200;
    --brand-secondary-gradient-start: #EE7200;
    --brand-secondary-gradient-end: #F18726;
    --brand-info: #2775DF;
    --brand-info-lighter: #20A1EB;
    --brand-info-lighter-2: #D5E7FB;
    --brand-info-lighter-3: #E3EFFC;
    --brand-info-darker: #2369C9;
    --brand-info-gradient-start: #2369C9;
    --brand-info-gradient-end: #3D83E2;
    --brand-success: #5C9620;
    --brand-success-lighter: #71AC34;
    --brand-success-lighter-2: #71AC34;
    --brand-success-lighter-3: #EDF7EC;
    --brand-success-lighter-4: #F5FFF4;
    --brand-success-darker: #4E7F1B;
    --brand-success-gradient-start: #4E7F1B;
    --brand-success-gradient-end: #6CA036;
    --brand-warning: #F9A900;
    --brand-warning-lighter: #FEB65B;
    --brand-warning-lighter-2: #FFE7C7;
    --brand-warning-lighter-3: #FFF4E6;
    --brand-warning-lighter-4: #FFF7ED;
    --brand-warning-darker: #EDA100;
    --brand-warning-gradient-start: #EDA100;
    --brand-warning-gradient-end: #FAB626;
    --brand-danger: #CC101C;
    --brand-danger-lighter: #E02430;
    --brand-danger-lighter-2: #FBE0E2;
    --brand-danger-lighter-3: #FDEEEF;
    --brand-danger-lighter-4: #FFF2F3;
    --brand-danger-darker: #B40E19;
    --brand-danger-gradient-start: #B40E19;
    --brand-danger-gradient-end: #D0232E;
    --gray-icons: #b9bdc5;
    --gray-icons-hover: #FEB65B;
    --body-bg:#F9F9F9;
  
  
    // Custom
    // Layout
    --main-footer-background: linear-gradient(to left, #333 0%, #454545 100%);
    
    // Typography
    --dropdown-hover-text-color: #ffffff;
    --primary-btn-color: #ffffff;
    
    // Logo
    --logo-color-primary: #3e3e3e;
    --logo-color-secondary: #808080;
    
    //panels tables list-groups and other ui elements bg
    --content-theme-background: #ffffff;
    --content-theme-background-hover: #EAEAEA;
  
    //custom divider color
    --divider-theme: #DADADA;
  
    --global-actions-btn-color: #D8D8D8;
    --global-actions-btn-bg: #FFFFFF;
    --global-actions-btn-icon: #fda73d;
    --global-actions-btn-circle: #D8D8D8;

    --brand-accent: #E7133B;
    --brand-accent-lighter: #e64562;
}

// dark theme
body.dark {
    //Brands
    --gray-base: #F3F3F3;
    --gray-lighter: #DBDBDB;
    --gray-lighter-2: #ACB0B8;
    --gray-lighter-3: #7C8088;
    --gray-lighter-4: #656565;
    --gray-faded: #313131;
    --gray-darker: #FFFFFF;
    --brand-primary: #FDA73D;
    --brand-primary-lighter: #feb65b;
    --brand-primary-lighter-2: #FFCA7F;
    --brand-primary-lighter-3: #FFD599;
    --brand-primary-lighter-4: #FFDFB2;
    --brand-primary-darker: #F18D12;
    --brand-primary-gradient-start: #1f1f1f;
    --brand-primary-gradient-end: #353535; 
    --brand-secondary: #F08C30;
    --brand-secondary-lighter: #FFA450;
    --brand-secondary-darker: #EF8C17;
    --brand-secondary-gradient-start: #1f1f1f;
    --brand-secondary-gradient-end: #353535 ;
    --brand-info: #56C8FC;
    --brand-info-lighter: #90CAFF;
    --brand-info-lighter-2: #ABCCD7;
    --brand-info-lighter-3: #AECCE6;
    --brand-info-darker: #3B659A;
    --brand-info-gradient-start: #1f1f1f;
    --brand-info-gradient-end: #353535;
    --brand-success: #93C352;
    --brand-success-lighter: #A5CF78;
    --brand-success-lighter-2: #6E9269 ;
    --brand-success-lighter-3: #7A9C76;
    --brand-success-lighter-4: #86A582;
    --brand-success-darker: #2F452E;
    --brand-success-gradient-start: #1f1f1f;
    --brand-success-gradient-end: #353535;
    --brand-warning: #FDA73D;
    --brand-warning-lighter: #FFCA7F;
    --brand-warning-lighter-2: #CFBA84;
    --brand-warning-lighter-3: #DCC791;
    --brand-warning-lighter-4: #E9D49D;
    --brand-warning-darker: #6A5827;
    --brand-warning-gradient-start: #1f1f1f;
    --brand-warning-gradient-end: #353535;
    --brand-danger: #EF5350;
    --brand-danger-lighter: #E57373;
    --brand-danger-lighter-2: #EEB4B0;
    --brand-danger-lighter-3: #EFBEBA;
    --brand-danger-lighter-4: #EFC7C4;
    --brand-danger-darker: #896562;
    --brand-danger-gradient-start: #1f1f1f;
    --brand-danger-gradient-end: #353535;
    --gray-icons: #b9bdc5;
    --gray-icons-hover: #FFCA7F; 
    --body-bg: #121212;


    // Custom
    // Layout
    --main-footer-background: #1f1f1f;

    // Typography
    --dropdown-hover-text-color: #ffffff;
    --primary-btn-color: #fff;

    // Logo
    --logo-color-primary: #7c7c7c;
    --logo-color-secondary: #d8d8d8;

    //panels tables list-groups and other ui elements bg
    --content-theme-background: #1f1f1f;
    --content-theme-background-hover: #333333;

    //custom divider color
    --divider-theme: #333333;

    --global-actions-btn-color: #4A4A4A;
    --global-actions-btn-bg: #2A2A2A;
    --global-actions-btn-icon: #F1F1F1;
    --global-actions-btn-circle: #fda73d;

    --brand-accent: #E7133B;
    --brand-accent-lighter: #e64562;
}

*, html, body {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
html{
    --rb: 'Roboto', sans-serif;
    scroll-behavior: smooth;
}
body{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: var(--body-bg);

    font-family: var(--rb);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
}
a {
    text-decoration: none;
}
ul{
    padding: 0;
    margin: 0;
    list-style: none;
}
main{
    height: 100%;
    flex-grow: 1;
    overflow: hidden;
}

.docs-page {
    & .container{
        max-width: 1142px;
    }
}