.block10{
    &__wrap{
        background-repeat: no-repeat;
        background-position: bottom right;
    }
    &__title{
        max-width: 560px;
        &>i{
            color: var(--brand-accent);
        }
    }
    &__subtitle{
        max-width: 560px;
    }
    &__action{
        max-width: 560px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 60px;
        & .btn{
            min-width: 200px;
        }
        &>p{
            // margin-left: 16px;
            margin-top: 16px;
            color: var(--gray-lighter-3);
            font-size: 18px;
            &>a{
                color: var(--gray-base);
                font-size: 16px;
                font-weight: 600;
                text-decoration-line: underline;
                transition: all 100ms linear; 
                &:hover{
                    opacity: 0.6;
                }
            }
        }
    }
}
@media screen and (max-width: 991px) {
    .block10{
        &__wrap{
            background-image: none !important;
        }
    }
}
@media screen and (max-width: 576px){
    .block10{
        &__title{
            max-width: 100%;
        }
        &__subtitle{
            max-width: 100%;
        }
        &__action{
            max-width: 100%;
            align-items: flex-start;
            flex-direction: column;
            margin-top: 30px;
            & .btn{
                width: 100%;
            }
            &>p{
                margin: 16px 0 0 0;
            }
        }
    }
}