.block14{
    &__wrap{
       display: grid;
    }
    &.three{
        & .block14__wrap{
            grid-template-columns: 1fr 1fr 1fr;
            grid-column-gap: 30px;
            grid-row-gap: 30px;
        }
        & .block14__col{
            padding: 30px;
        }
    }
    &.four{
        & .block14__wrap{
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-column-gap: 20px;
            grid-row-gap: 20px;
        }
        & .block14__col{
            padding: 28px;
        }
    }
    &__col{
        min-width: 200px;
        border-radius: 6px;
        background: var(--content-theme-background);

        &-header{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin-bottom: 24px;
            &-img{
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 6px;
                background: var(--body-bg);
                margin-left: 30px;
                &>img{
                    display: block;
                    width: 70px;
                    height: 70px;
                    object-fit: cover;
                }
            }
        }

        &-title{
            color: var(--gray-base);
            font-size: 24px;
            font-weight: 600;
        }
        &-subtitle{
            color: var(--gray-lighter-2);
            font-size: 18px;
            margin-top: 16px;
        }
        &-text{
            color: var(--gray-lighter-3);
            font-size: 16px;
            &:not(:last-child){
                margin-bottom: 16px;
            }
        }
    }
}

@media screen and (max-width: 1279px) {
    .block14{
        &.four{
            & .block14__wrap{
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 30px;
                grid-row-gap: 30px;
            }
        }
    }
}
@media screen and (max-width: 991px) {
    .block14{
        &.three{
            & .block14__wrap{
                grid-template-columns: 1fr 1fr;
            }
        }
    }
}


@media screen and (max-width: 767px) {
    .block14{
        &.three, &.four{
            & .block14__wrap{
                grid-template-columns: 1fr;
            }
        }
        &__wrap{
           grid-template-columns: 1fr;
        }
        &__col{
            min-width: 200px;
            width: 100%;
        }
    }
}

@media screen and (max-width: 576px){
    .block14{
        &__wrap{
           grid-column-gap: 16px;
           grid-row-gap: 16px;
        }
        &__col{    
            &-header{
                margin-bottom: 16px;
                &-img{
                    margin-left: 15px;
                }
            }
            &-subtitle{
                line-height: 1.2;
                margin-top: 12px;
            }
            &-text{
                &:not(:last-child){
                    margin-bottom: 12px;
                }
            }
        }
    }
}