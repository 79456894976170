.modal{
    padding: 60px;
    border-radius: 6px;
    background: var(--body-bg);
    max-width: 1290px;
    position: relative;
    &__backdrop{
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 15px;
        background: rgba(0, 0, 0, 0.5);
        &.show{
            display: flex;
        }
    }

    &__body, &__actions{
        width: 100%;
    }

    &__title{
        color: var(--gray-base);
        font-size: 40px;
        font-weight: 800;
        line-height: 46px;
    }
    &__subtitle{
        color: var(--gray-lighter-3);
        font-size: 20px;
        font-weight: 400;
        line-height: 26px;
        margin-top: 24px;
    }
    &__fields{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 32px;
        margin-bottom: 16px;
        & .modal__group{
            width: 50%;
            &:not(:last-child){
                margin-right: 16px;
            }
        }
    }
    &__btn{
        margin-top: 32px;
        min-width: 200px;
    }
    &__close-btn{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: 1;
        top: 30px;
        right: 30px;
        background: transparent;
        cursor: pointer;
        border: none;
        padding: 0;
        transition: all 100ms linear;
        &>i{
            display: inline-block;
            font-size: 24px;
            color: var(--gray-icons);
            pointer-events: none;
            transition: all 100ms linear;
        }
        &:hover, &:focus{
            opacity: 0.6;
        }
    }
    &__txt{
        color: var(--gray-lighter-3);
        font-size: 11px;
        font-weight: 400;
        line-height: 14px; 
        margin-top: 24px;
    }
    label{
        color: var(--gray-lighter-3);
        font-size: 20px;
        font-weight: 400;
        line-height: 26px;
        margin-bottom: 12px;
        &.error{
            margin: 0;
            font-size: 14px;
            font-weight: 400;
            line-height: 1;
            color: var(--brand-danger);
        }
    }
    input{
        display: block;
        width: 100%;
        border-radius: 6px;
        border: 1px solid var(--divider-theme);
        background: var(--content-theme-background);
        font-size: 20px;
        font-weight: 400;
        line-height: 26px;
        color: var(--gray-base);
        height: 46px;
        padding: 10px;
        outline: none;
        transition: all 100ms linear;
        &:hover, &:focus{
            border-color: var(--brand-accent);
        }
    }
    textarea{
        display: block;
        width: 100%;
        border-radius: 6px;
        border: 1px solid var(--divider-theme);
        background: var(--content-theme-background);
        resize: vertical;
        font-family: var(--rb);
        font-size: 20px;
        font-weight: 400;
        line-height: 26px;
        margin-bottom: 12px;
        color: var(--gray-base);
        padding: 10px;
        outline: none;
        transition: all 100ms linear;
        &:hover, &:focus{
            border-color: var(--brand-accent);
        }
    }
}
.captcha_wrap{
    display: none;
}
.grecaptcha-badge{
    display: none;
}

@media screen and (max-width: 767px) {
    .modal{
        width: 100%;
        height: 100%;
        border-radius: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        &__backdrop{
            padding: 0;
        }
        &__fields{
            flex-direction: column;
            & .modal__group{
                width: 100%;
                &:not(:last-child){
                    margin: 0 0 16px 0;
                }
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .modal{
        padding: 30px;
        &__title{
            font-size: 36px;
            line-height: 1;
        }
        &__subtitle{
            margin-top: 12px;
            font-size: 16px;
            line-height: 1.2;
        }
        &__fields{
            margin-top: 16px;
        }
        &__btn{
            width: 100%;
            margin-top: 16px;
        }
        &__close-btn{
            top: 15px;
            right: 15px;
        }
    }
}