.back-to-top{
    display: block;
    width: 60px;
    height: 60px;
    position: fixed;
    z-index: 10;
    bottom: 100px;
    right: 25px;
    background-color: var(--content-theme-background);
    border: 1px solid var(--divider-theme);
    border-radius: 6px;
    transition: all 250ms linear;
    opacity: 0;
    cursor: pointer;
    pointer-events: none;
    & i{
        display: block;
        font-size: 22px;
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 2;
        transform: translate(-50%, -50%);
        font-weight: 900;
        font-style: normal;
        color: var(--gray-icons);
        transition: color 250ms ease;
    }
    &.show{
        opacity: 0.65;
        pointer-events: all;
        &:hover{
            opacity: 1;
            & i{
                color: var(--brand-accent);
            }
        }
    }
}